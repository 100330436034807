const VAR_SEARCH_OPEN = 'global-search-open'
const VAR_SEARCH_HISTORY = 'global-search-history'
const VAR_SEARCH_LOADING = 'global-search-loading'
const VAR_SEARCH_RESULTS = 'global-search-results'
const VAR_SEARCH_STATE = 'global-search-state'
const VAR_SEARCH_QUERY = 'global-search-query'
const VAR_SEARCH_TARGET_BLANK = 'global-search-target-blank'
const VAR_SEARCH_SELECTED = 'global-search-selected'
const VAR_SEARCH_HISTORY_MAX = 6

export const useSearchOpen = () => useState(VAR_SEARCH_OPEN, () => false)

interface TranslatedField<T> {
  en?: T
  fr?: T
}

export interface SearchResultArticle {
  type: 'article'
  objectID: string
  data: {
    title: TranslatedField<string>
    slug: TranslatedField<string>
    description: TranslatedField<string>
    image: string
    imageAlt: TranslatedField<string>
    conditions: string[]
    publishedAt: string
    timeToRead: TranslatedField<number>
    _highlightResult?: any
  }
}

export interface SearchResultApp {
  type: 'app'
  objectID: string
  data: {
    id: string
    title: TranslatedField<string>
    icon: string
    conditions: string[]
    developerName: string
    _highlightResult?: any
  }
}

export type SearchResult = SearchResultArticle | SearchResultApp
type SearchState = 'history-empty' | 'history' | 'results' | 'empty'

export function useGlobalSearch() {
  const open = useSearchOpen()
  const openBlank = useState(VAR_SEARCH_TARGET_BLANK, () => false)
  const loading = useState(VAR_SEARCH_LOADING, () => false)
  const results = useState<SearchResult[]>(VAR_SEARCH_RESULTS, () => [])
  const history = useState<SearchResult[]>(VAR_SEARCH_HISTORY, () => [])
  const state = useState<SearchState>(VAR_SEARCH_STATE, () => 'history-empty')

  onMounted(() => {
    const savedHistory = localStorage.getItem(VAR_SEARCH_HISTORY) || '[]'
    history.value = JSON.parse(savedHistory)

    if (history.value.length)
      state.value = 'history'
  })

  const query = useState<string>(VAR_SEARCH_QUERY, () => '')
  const selected = useState<number>(VAR_SEARCH_SELECTED, () => 0)

  watch(query, async (q) => {
    selected.value = 0

    if (q.length < 1) {
      results.value = []
      state.value = history.value.length ? 'history' : 'history-empty'
      loading.value = false
      return
    }

    await search(q)
    state.value = results.value.length ? 'results' : 'empty'
  })

  const algolia = useAlgoliaRef()
  async function search(q: string) {
    loading.value = true

    const response = await algolia.search([{
      indexName: 'articles',
      query: q,
      params: {
        hitsPerPage: 2,
      },
    }, {
      indexName: 'summaries',
      query: q,
      params: {
        hitsPerPage: 16,
      },
    }])

    const responseArticles: SearchResultArticle[] = response.results[0].hits.map((hit: any) => {
      const postEnglish = hit.posts.find((post: any) => post.language === 'en') || hit.posts[0]
      const postFrench = hit.posts.find((post: any) => post.language === 'fr')

      return {
        type: 'article',
        objectID: hit.objectID,
        data: {
          title: {
            en: postEnglish?.title,
            fr: postFrench?.title,
          },
          slug: {
            en: postEnglish?.slug,
            fr: postFrench?.slug,
          },
          description: {
            en: postEnglish?.meta_description,
            fr: postFrench?.meta_description,
          },
          image: hit.card_image_url,
          imageAlt: {
            en: postEnglish?.card_image_alt,
            fr: postFrench?.card_image_alt,
          },
          conditions: hit.conditions,
          publishedAt: hit.published_at,
          timeToRead: {
            en: postEnglish?.time_to_read,
            fr: postFrench?.time_to_read,
          },
          // _highlightResult: hit._highlightResult,
        },
      }
    })
    const responseApps: SearchResultApp[] = response.results[1].hits.map((hit: any) => ({
      type: 'app',
      objectID: hit.objectID,
      data: {
        id: hit.objectID,
        title: {
          en: hit.title,
          fr: hit.title_fr || hit.title,
        },
        icon: hit.icon,
        conditions: hit.conditions,
        developerName: hit.developer_name,
        // _highlightResult: hit._highlightResult,
      },
    }))

    loading.value = false
    results.value = [...responseArticles, ...responseApps]
  }

  function handleClose() {
    open.value = false
    openBlank.value = false
    query.value = ''
    results.value = []
    loading.value = false
    state.value = history.value.length ? 'history' : 'history-empty'
    selected.value = 0
  }

  function handleClick(result: SearchResult) {
    handleClose()

    const currentHistory = [...history.value]
    const existIndex = currentHistory.findIndex(({ objectID }) => objectID === result.objectID)

    if (existIndex >= 0)
      currentHistory.splice(existIndex, 1)

    currentHistory.unshift(result)

    if (currentHistory.length > VAR_SEARCH_HISTORY_MAX)
      currentHistory.pop()

    history.value = currentHistory
    localStorage.setItem(VAR_SEARCH_HISTORY, JSON.stringify(currentHistory))
  }

  const lastIndex = computed(() => {
    const length = state.value === 'history' ? history.value.length : results.value.length
    return Math.max(length - 1, 0)
  })

  function handleArrowUp() {
    if (selected.value === 0)
      selected.value = lastIndex.value
    else
      selected.value -= 1
  }

  function handleArrowDown() {
    if (selected.value === lastIndex.value)
      selected.value = 0
    else
      selected.value += 1
  }

  return {
    query,
    history,
    loading,
    results,
    selected,
    openBlank,
    state,

    handleClose,
    handleClick,
    handleArrowUp,
    handleArrowDown,
  }
}
